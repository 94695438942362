<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="flex items-end gap-x-6 mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">
      Ride Histories Requests
    </h1>

    <!-- Adapt table for mobile devices -->
    <table class="grid w-full gap-y-4 text-center">
      <thead>
        <tr
          class="grid grid-cols-[repeat(5,_minmax(96px,_1fr)),_minmax(48px,_96px)] items-center gap-x-2 border-b border-silver-fog pb-1.5 text-ghost-gray"
        >
          <th class="text-left font-normal">Request ID</th>
          <th class="font-normal">User Email (ID)</th>
          <th class="font-normal">Created At</th>
          <th class="font-normal">Status</th>
          <th class="font-normal">Action</th>
        </tr>
      </thead>
      <tbody class="space-y-2">
        <tr
          v-for="(request, index) in ridesHistoryRequests"
          :key="`rides-${index}`"
          class="grid grid-cols-[repeat(5,_minmax(96px,_1fr)),_minmax(48px,_96px)] gap-x-2 rounded-md"
        >
          <td>{{ request.id }}</td>
          <td>{{ request.user.email + ' (' + request.user.id + ')' }}</td>
          <td>{{ request.created_at.replaceAll('T', ' ').split('.')[0] }}</td>
          <td
            :class="
              request.status === 'pending'
                ? 'bg-blue-500'
                : request.status === 'succeed'
                ? 'bg-green-500'
                : 'bg-red-500'
            "
            class="text-white font-bold py-2 px-2 rounded"
          >
            {{ request.status }}
          </td>
          <td v-if="request.status === 'pending' && checkPermission('ride-history-requests.view')">
            <div class="inline-flex">
              <button
                @click="ridesStore.statusChosen(request.id, 'refused')"
                class="bg-red-500 hover:bg-red-700 text-gray-800 font-bold py-2 px-4 rounded-l"
              >
                Decline
              </button>
              <button
                @click="ridesStore.statusChosen(request.id, 'succeed')"
                class="bg-green-500 hover:bg-green-400 text-gray-800 font-bold py-2 px-4 rounded-r"
              >
                Succeed
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script setup>
import { inject } from 'vue'
import { useRidesStore } from '@/stores/rides'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useCentrifugoStore } from '@/stores/centrifugo'
import centrifugo from '@/plugins/centrifugo'

const ridesStore = useRidesStore()
const userStore = useUserStore()
let { ridesHistoryRequests } = storeToRefs(ridesStore)
const menuCollapsed = inject('menuCollapsed')

const checkPermission = (permission) => {
  return userStore.checkPermission(permission)
}

const centrifugoStore = useCentrifugoStore()

centrifugoStore.getToken().then((centrifugoToken) => {
  centrifugo.connect(
    'as.history.request.created',
    'Orders.History.Request.Created',
    centrifugoToken,
    (data) => {
      ridesHistoryRequests.value.push(data.rideHistoryRequest)
      ridesStore.update(ridesHistoryRequests.value)
    }
  )
})
</script>

<style scoped></style>
